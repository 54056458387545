<template>
  <div class="vg_wrapper">
    <el-tabs v-model="activeName" type="border-card">
      <el-tab-pane label="单据信息" name="first" :key="'first'">
        <childTab1 />
      </el-tab-pane>
    </el-tabs>
  </div>
</template>

<script>
import CustAddMain from '@/views/MessageManagement/CustManage/TabChild/CustAddMain';
export default {
  name: 'CustAdd',
  components: {
    childTab1: CustAddMain
  },
  data() {
    return {
      activeName: 'first'
    };
  }
};
</script>

<style scoped></style>
